import React from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";


const Slider9 = () => {
  return (
    <div className={Styles.slider9}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <p></p>
              <img
                src="/aboutus/mission.png"
                alt="Sustainable World Organization "
              />
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <div className={Styles.slide9lightbackground}>
              <h2>Our Mission</h2>
              <h2>
                {" "}
                To transform lives of vulnerable members of society through a
                data-driven decision making, partnership building, lobby,
                advocacy for a jointed public, private and non-profit sectors
                collaborative approach to sustainable development.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider9;

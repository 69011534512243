import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

import SkeletonDisplay from "../../hooks/skeletonDisplay";

const StudentMentorshipContent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Student Mentorship </h1>
            <h3>THE CHALLENGE</h3>
            <p>
              Today’s young people are faced with myriad of challenges that
              affect their academic success, emotional wellbeing and social
              life, negatively impacting on their lives as responsible,
              productive and successful citizens. Absentee parents, ill-equipped
              and unmotivated teachers; inadequate learning
              opportunities/facilities; insecure learning environments coupled
              with global challenges such as maintaining healthy lifestyles,
              climate change-related shocks and exposure to the negative
              contents of ICT such as social media, pornography, and the
              COVID-19 pandemic continue to adversely affect our high school
              students. This leads to increased levels stress, poor academic
              performance, truancy/school drop-outs, teenage pregnancies,
              sexually transmitted diseases, anti-social behaviors, suicide,
              drug and substance abuse, hooliganism, crime, including violent
              extremism and inability to productively fit into the society.
            </p>
            <div className={Styles.productCard}>
              <h3>Our Programs</h3>
              {list.map((item, index) => (
                <li key={index}>
                  <Link to={`/${item}`}>{item}</Link>
                </li>
              ))}
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/whatwedo/studentmentorship1.jpg"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div className={Styles.bodytext}>
          <h4>THE PROPOSED SOLUTION</h4>
          <p>
            To remedy this situation, Sustainable World Organization (SWO), a
            national NGO, in partnership with the Department of Education,
            County Government of Isiolo, Isiolo Parents’ Association and Isiolo
            Interfaith Network, have been working with schools through
            holiday-based student mentorship programme that has so far mentored
            155 students in five schools in Isiolo County. But this one-off
            mentorship approach is not enough. Based on the lessons learnt and
            the increasing demand for the programme, SWO has re-designed a
            school-based holistic student mentorship support program that seeks
            to develop students who are academically, emotionally, physically,
            socially, spiritually and productively fit for society. The program
            seeks to positively impact directly on 1,280 students in 5 schools
            in Isiolo County through an innovative, holistic, continuous
            accompaniment through their school-life’s journey to success
          </p>
          <SkeletonDisplay>
            <img
              src="/whatwedo/studentmentorship2.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
        </div>
        <div className={Styles.bodytextright}>
          <h4>HOLISTIC SCHOOL-BASED PEER MENTORSHIP BOOT CAMP</h4>
          <SkeletonDisplay>
            <img
              src="/aboutus/aboutusbodyimage1.png"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <p>
            The proposed ‘Make Education Count Program directly target 1,280
            students (640 girls, 640 boys) 10 school administrators, 10 parents
            and in 5 high school in Isiolo and 3 education officials.
            Indirectly, the program will impact on approximately 1,600 students
            in 10 schools. The program has the following key components: a)
            building the capacity of a network of school-based student peer
            mentors, b) Adopt-a-Mentee Scheme where experienced local mentors
            are linked and provide continuous accompaniment support to mentees,
            c) Wajibika na Elimu Forum to proactively engage with parents and
            school administration; d) On-Call psycho-social support to affected
            needy students, and e) Material support to needy/vulnerable students
            in form of tuition bursaries and personal effects.
          </p>
          <h4>To kick-start this programme</h4>
          <p>
            SWO and partners have designed a 3-days boot-camp for life-skills
            experiential learning for 30 students, 10 parents and 10 school
            administrators and 3 education officials. The boot-camp is designed
            as a life-changing, interactive, hands-on, learning experience in
            which carefully selected participants from participating schools in
            Isiolo will interact with a variety of highly skilled and
            experienced mentors, life-skills coaches, and counsellors will
            enable the youth to develop holistically (mentally, physically,
            socially, and spiritually) through imparting creative problem
            solving, social/interpersonal, spiritual and physical fitness
            knowledge, attitudes and practices.
          </p>
          <Link to="/Contact Us" style={{ textDecoration: "none" }}>
            <button>Get in Touch</button>
          </Link>
        </div>
        <div className={Styles.bodytextcenter}>
          <SkeletonDisplay>
            <img
              src="/whatwedo/studentmentorship3.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
        </div>
      </div>
      <div className={Styles.container}>
        <div className={Styles.productCard}>
          <div className={Styles.advert}>
            <h1>Make Education Count Campaign </h1>
            <h3>To Contribute</h3>
            <h4>MPESA PAYBILL NO: 247247</h4>
            <h4>ACCOUNT NO: 1130280581094</h4>
            <h3>Contact</h3>
            <h4>Tel: +254 72502170 / 0759842972</h4>
            <h4>Email:admin@sustainableworld.or.ke</h4>
          </div>
        </div>
        <div className={Styles.productCard}>
          <div className={Styles.advert2}>
            <SkeletonDisplay>
              <img
                src="/whatwedo/studentmentorship4.jpg"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentMentorshipContent;

const list = [
  "Student Mentorship",
  "Economic Empowerment",
  "Education",
  "Access to Health",
  "Peace and Cohesion",
  "Land & Natural Resource Management",
  "Resilience | Social Safety Net| Emergency Response",
  "Good Governance",
  "Holistic School-Based Peer Mentorship Boot Camp",
];

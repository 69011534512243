import React from "react";
import Styles from "./Styles.module.css";

import OurStrategiesCards from "./ourstrategiescards/ourstrategiescards";

const OurStrategiesContent = () => {
  return (
    <div className={Styles.slider4}>
      <div className={Styles.mainContainer}>
        <div className={Styles.bodytext}>
          <h1>OUR STRATEGIES</h1>
          <p>
            At SWO, we utilize innovative, sustainable and community-rooted
            approaches to bring about positive changes for women and youth. We
            are cognizant of the complexity of the deep-rooted challenges that
            many women/girls and youth face on a day-to-day basis. We are also
            alive to the many retrogressive politico-social-cultural practices
            that have made it difficult for these challenges to be overcome. We
            realize that many of the current methods used by various actors have
            not yielded the necessary results because they have not taken into
            consideration the deep-rootedness and the dynamic nature of these
            challenges, and also the age-old, tested and effective traditional
            methods that these communities use to communicate and interact with
            each other . The creative ways SWO uses to engage with our
            stakeholders include :
          </p>
        </div>
        <div className={Styles.bodytextright}>
          <OurStrategiesCards />
        </div>
      </div>
    </div>
  );
};

export default OurStrategiesContent;

import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";
import SkeletonDisplay from "../hooks/skeletonDisplay";

const Slider2 = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/aboutswohome.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <h3>Who is Sustainable World Organization ?</h3>
            <p>
              Sustainable World Organization (SWO) is a national NGO established
              in 2009 to promote sustainable development in Kenya.SWO (formerly
              Solutions for Women) began as women’s empowerment organisation but
              evolved into multi-sectoral, multi-stakeholder,holistic, national
              development organisation dedicated to making development work for
              all, especially, vulnerable groups (women, girls, youth and other
              socially excluded segments of society, enabling them achieve their
              full potential and contribute to local, national and international
              sustainable development goals.
            </p>
            <p>
              We strive to build a critical mass of actors – connecting the
              individuals, groups and organisations in the public, private and
              non-profit sectors in a collaborative and result-oriented manner
              to expand opportunities for the last mile communities in order to
              achieve lasting impacts locally and globally.
            </p>

            <div>
              <Link to="/About Us" style={{ textDecoration: "none" }}>
                <button className={Styles.infobtnblue}>Learn More</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider2;

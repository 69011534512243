import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

import SkeletonDisplay from "../../hooks/skeletonDisplay";

const EconomicEmpowermentContent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Economic Empowerment </h1>
            <p>
            SWO identifies practical solutions to economic challenges of women and youth and test them out with partner women and youth self-help groups. Some of these ideas which SWO is testing out include yoghurt making, hay businesses and SME capacity building support programmes targeting pastoral women and youth groups. Once a group or SME is confident of the new entrepreneurship initiative, SWO supports their access to and effective use of capital through entrepreneurship skills training and linkages with financial support such as Youth and Women Enterprise Funds.
            </p>
            <div className={Styles.productCard}>
              <h3>Our Programs</h3>
              {list.map((item, index) => (
                <li key={index}>
                  <Link to={`/${item}`}>{item}</Link>
                </li>
              ))}
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/aboutusswo.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
        </div>
        
        
        
      </div>
    </div>
  );
};

export default EconomicEmpowermentContent;

const list = [
  "Student Mentorship",
  "Economic Empowerment",
  "Education",
  "Access to Health",
  "Peace and Cohesion",
  "Land & Natural Resource Management",
  "Resilience | Social Safety Net| Emergency Response",
  "Good Governance",
  "Holistic School-Based Peer Mentorship Boot Camp",
];

import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../hooks/skeletonDisplay";
import EducationContent from "./sliders/educationcontent";

function Education() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img
              src="/aboutus/education.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button> Education</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <EducationContent/>
      </div>
    </div>
  );
}

export default Education;

import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

import SkeletonDisplay from "../../hooks/skeletonDisplay";

const GoodGovernanceContent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Good Governance </h1>
            <p>
              Sustainable World Organization (SWO) is committed to promoting
              awareness and education on rights, constitutional provisions, and
              their implications for women, youth, and socially excluded groups.
              Through targeted training programs, we empower these communities
              to understand and claim their rights, enhancing their
              participation and inclusion in all spheres of societal affairs.
              Our efforts aim to create a more equitable society where every
              individual, regardless of their background, can contribute
              meaningfully to development and decision-making processes.
            </p>
            <div className={Styles.productCard}>
              <h3>Our Programs</h3>
              {list.map((item, index) => (
                <li key={index}>
                  <Link to={`/${item}`}>{item}</Link>
                </li>
              ))}
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/aboutusswo.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div className={Styles.bodytext}>
          <h4>Our Approach</h4>
          <p>
            SWO also actively advocates against harmful cultural practices that
            perpetuate inequality and violence. These include Sexual and
            Gender-Based Violence (SGBV), Female Genital Mutilation (FGM), early
            marriages, and violations of the rights of widows and bereaved
            children. By addressing these retrogressive practices within the
            framework of the law, we work to protect the dignity, safety, and
            well-being of vulnerable populations. Our holistic approach combines
            community sensitization, policy advocacy, and legal support to
            foster cultural transformation and uphold the rights of all
            individuals.
          </p>
          <SkeletonDisplay>
            <img
              src="/aboutus/aboutusbodyimage.png"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
       
        </div>
       
      </div>
    </div>
  );
};

export default GoodGovernanceContent;

const list = [
  "Student Mentorship",
  "Economic Empowerment",
  "Education",
  "Access to Health",
  "Peace and Cohesion",
  "Land & Natural Resource Management",
  "Resilience | Social Safety Net| Emergency Response",
  "Good Governance",
  "Holistic School-Based Peer Mentorship Boot Camp",
];

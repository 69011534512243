import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../hooks/skeletonDisplay";
import PeaceandCohesionContent from "./sliders/peaceandcohesioncontent";

function PeaceandCohesion() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img
              src="/aboutus/peaceandcohesion.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button> Peace and Cohesion</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <PeaceandCohesionContent />
      </div>
    </div>
  );
}

export default PeaceandCohesion;

import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

import SkeletonDisplay from "../../hooks/skeletonDisplay";

const OnlineMentorshipContent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Online Mentorship Programs</h1>
            <p>
              Sustainable World Organization (SWO) is a national NGO established
              in 2009 to promote sustainable development in Kenya.SWO (formerly
              Solutions for Women) began as women’s empowerment organisation but
              evolved into multi-sectoral, multi-stakeholder,holistic, national
              development organisation dedicated to making development work for
              all, especially, vulnerable groups (women, girls, youth and other
              socially excluded segments of society, enabling them achieve their
              full potential and contribute to local, national and international
              sustainable development goals.
            </p>
            <div className={Styles.productCard}>
              <h3>Online Mentorship Programs</h3>
              {list.map((item, index) => (
                <li key={index}>
                  <Link to={`/${item}`}>{item}</Link>
                </li>
              ))}
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/aboutusswo.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div className={Styles.bodytext}>
          <h4>Our Approach</h4>
          <p>
            We strive to build a critical mass of actors – connecting the
            individuals, groups and organisations in the public, private and
            non-profit sectors in a collaborative and result-oriented manner to
            expand opportunities for the last mile communities in order to
            achieve lasting impacts locally and globally. Our approaches are
            rooted in innovative, participatory and evidence-based, data-driven
            decision making that applies tested (traditional and modern),
            cutting-edge frameworks, techniques and tools that seeks to build
            bottom-up networks and horizontal partnerships for positive, lasting
            change.
          </p>
          <SkeletonDisplay>
            <img
              src="/aboutus/aboutusbodyimage.png"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <p>
            From radicalization to malnutrition, climate change to exclusion,
            SGBV to insecurity, corruption to unemployment, our interventions
            cut across the sustainable human development spectrum – targeting
            sectors with most endemic challenges and greatest implications for
            the world’s most excluded – women, girls, youth, disabled and
            minorities – to expand their opportunities for a
            socially-acceptable, economically-viable, environmentally–friendly,
            just and equitable world. We work at the frontiers of
            social-economic and political exclusion, targeting the
            unreached/unserved.
          </p>
        </div>
        <div className={Styles.bodytextright}>
          <h4>Our Experience</h4>
          <SkeletonDisplay>
            <img
              src="/aboutus/aboutusbodyimage1.png"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <p>
            In Nairobi, we have worked with communities/local groups in the
            Mathare slum, promoting better healthcare practices and HIV/AIDS
            prevention, while in Isiolo we have supported children’s homes,
            trained women and youth to strengthen their organizational
            capacities, gender mainstreaming, student mentorship and
            entrepreneurship skills in preparation and sell of local Probiotic
            Yoghurt and Access to Finance. In building peace and cohesion, we
            have partnered with the Isiolo Inter-Faith Council to organize
            sensitization seminars for various mixed-faith groups to train on
            peacebuilding and conflict prevention.
          </p>
          <h4>Our Sponsors</h4>
          <p>
            {" "}
            We are currently partnering with the USAID/AGA KHAN FOUNDATION/YETU
            INITIATIVE to mobilize support for the creation Isiolo Local
            Development Trust – An Umbrella organization bring together CSOs,
            Private Sector, FBOs and the County Government to achieve
            socio-economic development in Isiolo. We have also partnered with
            County Government of Isiolo to establish and operationalize
            Performance Management System to enhance the quality of public
            service Delivery. SWO has its headquarters in Nairobi with
            activities in Isiolo and Marsabit Counties.
          </p>
          <Link to="/Contact Us" style={{ textDecoration: "none" }}>
            <button>Get in Touch</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OnlineMentorshipContent;

const list = [
  "Student Mentorship",
  "Economic Empowerment",
  "Education",
  "Access to Health",
  "Peace and Cohesion",
  "Land & Natural Resource Management",
  "Resilience | Social Safety Net| Emergency Response",
  "Good Governance",
  "Holistic School-Based Peer Mentorship Boot Camp",
];

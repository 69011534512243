import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

import SkeletonDisplay from "../../hooks/skeletonDisplay";

const PeaceandCohesionContent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Peace and Cohesion </h1>
            <p>
              SWO promotes peaceful co-existence among and between communities
              especially in northern Kenya who are prone to and are highly
              affected by conflicts and cattle rustling. SWO engages with women
              and youth on issues of Countering Violent Extremism (CVE) and
              promotes social cohesion and integration of young people affected
              by extremist ideologies. SWO advocates for women’s voice and role
              in peacebuilding and conflict resolution.
            </p>
            <div className={Styles.productCard}>
              <h3>Our Programs</h3>
              {list.map((item, index) => (
                <li key={index}>
                  <Link to={`/${item}`}>{item}</Link>
                </li>
              ))}
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/aboutusswo.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div className={Styles.bodytext}>
          <h4>Our Approach</h4>
          <p>
            Our efforts extend to engaging women and youth in critical
            conversations and actions on Countering Violent Extremism (CVE). By
            equipping them with tools and knowledge, we empower these groups to
            become champions of social cohesion and agents of change in their
            communities. Additionally, we prioritize the integration of young
            people affected by extremist ideologies, offering them pathways to
            reintegration and positive societal contribution. SWO also advocates
            for the vital role and voice of women in peacebuilding and conflict
            resolution, ensuring their active participation in shaping more
            inclusive and sustainable solutions for long-term peace.
          </p>
          <SkeletonDisplay>
            <img
              src="/aboutus/aboutusbodyimage.png"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          
        </div>
       
      </div>
    </div>
  );
};

export default PeaceandCohesionContent;

const list = [
  "Student Mentorship",
  "Economic Empowerment",
  "Education",
  "Access to Health",
  "Peace and Cohesion",
  "Land & Natural Resource Management",
  "Resilience | Social Safety Net| Emergency Response",
  "Good Governance",
  "Holistic School-Based Peer Mentorship Boot Camp",
];

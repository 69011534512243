import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

import SkeletonDisplay from "../../hooks/skeletonDisplay";

const AccesstoHealthContent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Access to Health </h1>
            <p>
              Our approach to improving access to health is rooted in
              inclusivity, innovation, and collaboration. We focus on addressing
              both immediate healthcare needs and the systemic barriers that
              limit access for vulnerable populations. By leveraging technology,
              such as telemedicine platforms and digital health tools, we bring
              essential services closer to underserved communities.
            </p>
         
            <div className={Styles.productCard}>
              <h3>Access To Health Content</h3>
              {list.map((item, index) => (
                <li key={index}>
                  <Link to={`/${item}`}>{item}</Link>
                </li>
              ))}
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/aboutusswo.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div className={Styles.bodytext}>
          <h4>Our Approach</h4>
          <p>
              We work closely with stakeholders—governments, healthcare
              providers, and community organizations—to strengthen healthcare
              systems, enhance affordability, and ensure quality care. Our
              initiatives prioritize preventive care, health education, and
              awareness campaigns to empower individuals to make informed
              decisions about their well-being.
            </p>
            <p>
              Through a holistic lens, we also tackle the social determinants of
              health, including nutrition, sanitation, and housing, creating
              sustainable solutions that foster healthier and more equitable
              communities.
            </p>
          <SkeletonDisplay>
            <img
              src="/aboutus/aboutusbodyimage.png"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          
        </div>
        
      </div>
    </div>
  );
};

export default AccesstoHealthContent;

const list = [
  "Student Mentorship",
  "Economic Empowerment",
  "Education",
  "Access to Health",
  "Peace and Cohesion",
  "Land & Natural Resource Management",
  "Resilience | Social Safety Net| Emergency Response",
  "Good Governance",
  "Holistic School-Based Peer Mentorship Boot Camp",
];

import React from "react";
import Styles from "./Styles.module.css";
import { Zoom } from "react-awesome-reveal";
import SkeletonDisplay from "../../hooks/skeletonDisplay";
import AccordianComponent from "../../hooks/Accordian";

const Slider4 = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <Zoom>
              <h1>Frequently Asked Questions</h1>
              <p>
                The Sustainable World Organization (SWO) is a dynamic Kenyan
                national NGO with a rich history of driving sustainable
                development since its inception in 2009. Initially established
                as Solutions for Women, SWO focused on women’s empowerment but
                has since grown into a multi-sectoral, multi-stakeholder
                organization addressing diverse aspects of sustainable
                development. The NGO adopts a holistic approach, prioritizing
                the inclusion of vulnerable groups—such as women, girls, youth,
                and other socially excluded segments—enabling them to unlock
                their potential and contribute meaningfully to local, national,
                and global development goals.
              </p>
              <div className={Styles.productCard}></div>
            </Zoom>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/swofaq.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div>
          <h3>Our Programs</h3>
          <AccordianComponent />
        </div>
      </div>
    </div>
  );
};

export default Slider4;


import React from "react";
import { Routes, Route } from "react-router-dom";
import Nomatch from "./nomatch";
import Home from "./home";
import Layout from "./components/layout/Layout";
import Layout2 from "./components/layout/Layout2";
import AboutUs from "./components/generalpages/aboutus";
import ContactUs from "./components/generalpages/contactus";
import Donate from "./components/generalpages/donate";
import Careers from "./components/generalpages/careers";
import FAQs from "./components/generalpages/faqs";
import PrivacyPolicy from "./components/generalpages/privacypolicy";
import SiteMap from "./components/generalpages/sitemap";
import TermsOfService from "./components/generalpages/termsofservice";

// Where we Work
import Governance from "./components/whoweare/governance";
import OurStrategies from "./components/whoweare/ourstrategies";
import Partnerships from "./components/whoweare/partnerships";
import WhodoweWorkwith from "./components/whoweare/whodoweworkwith";
import StudentMentorship from "./components/whatwedo/studententorship";
import EconomicEmpowerment from "./components/whatwedo/economicempowerment";
import Education from "./components/whatwedo/education";
import AccesstoHealth from "./components/whatwedo/accesstohealth";
import LandNaturalResource from "./components/whatwedo/landnaturalresourcemanagement";
import ResilienceSocialSafetyNet from "./components/whatwedo/resiliencesocialsafetyemergencyresponse";
import GoodGovernance from "./components/whatwedo/goodgovernance";
import HolisticSchoolPeer from "./components/whatwedo/holisticschoolbasedpeerbootcamp";
import PeaceandCohesion from "./components/whatwedo/peaceandcohesion";
import OnlineMentorship from "./components/whatwedo/onlinementorshipprogram";
import Gallery from "./components/generalpages/gallery";
import GalleryCategory from "./components/generalpages/gallery/GalleryCategory";
// What we Do

function AppRouter() {
  return (
    <>
      <Routes>
        <>
          {/* Layout1 */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/Contact Us" element={<ContactUs />}></Route>
            <Route path="/Careers" element={<Careers />}></Route>
            <Route
              path="/Frequently Asked Questions"
              element={<FAQs />}
            ></Route>
            <Route path="/Privacy Policy" element={<PrivacyPolicy />}></Route>
          </Route>{" "}
        </>
        <>
          {/* Layout2 */}
          <Route path="/" element={<Layout2 />}>
            <Route path="/About Us" element={<AboutUs />} />
            <Route path="/Donate" element={<Donate />}></Route>

            <Route path="/Site Map" element={<SiteMap />}></Route>
            <Route
              path="/Terms Of Service"
              element={<TermsOfService />}
            ></Route>
            <Route path="*" element={<Nomatch />} />
          </Route>
        </>
        <>
          {/* Who We Are */}
          <Route path="/" element={<Layout2 />}>
            <Route path="/Governance" element={<Governance />} />
            <Route path="/Our Strategies" element={<OurStrategies />} />
            <Route path="/Partnerships" element={<Partnerships />} />
            <Route path="/Who Do We Work With" element={<WhodoweWorkwith />} />
          </Route>
        </>
        <>
          {/* What We do| */}
          <Route path="/" element={<Layout2 />}>
            <Route path="/Student Mentorship" element={<StudentMentorship />} />
            <Route
              path="/Economic Empowerment"
              element={<EconomicEmpowerment />}
            />
            <Route path="/Education" element={<Education />} />
            <Route path="/Access to Health" element={<AccesstoHealth />} />
            <Route path="/Peace and Cohesion" element={<PeaceandCohesion />} />
            <Route
              path="/Land Natural Resource Management"
              element={<LandNaturalResource />}
            />
            <Route
              path="Resilience Social Safety Net Emergency Response"
              element={<ResilienceSocialSafetyNet />}
            />
            <Route path="/Good Governance" element={<GoodGovernance />} />
            <Route path="/Gallery" element={<Gallery />} />
            <Route
              path="/Gallery/:categoryData"
              element={<GalleryCategory />}
            />
            <Route
              path="/Online Mentorship Programs"
              element={<OnlineMentorship />}
            />
            <Route
              path="/Holistic School-Based Peer Mentorship Boot Camp"
              element={<HolisticSchoolPeer />}
            />
          </Route>
        </>
      </Routes>
    </>
  );
}

export default AppRouter;

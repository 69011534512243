import React from "react";
import Styles from "./Styles.module.css";
import StudentMentorshipContent from "./sliders/studententorshipcontent";
import SkeletonDisplay from "../hooks/skeletonDisplay";

function StudentMentorship() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img
              src="/aboutus/studentmentorship.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button> Student Mentorship</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <StudentMentorshipContent />
      </div>
    </div>
  );
}

export default StudentMentorship;

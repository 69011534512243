import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../hooks/skeletonDisplay";
import AccesstoHealthContent from "./sliders/accesstohealthcontent";

function AccesstoHealth() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img
              src="/aboutus/accesstohealth.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button> Access to Health</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <AccesstoHealthContent />
      </div>
    </div>
  );
}

export default AccesstoHealth;

import PartnersCarousel from "./partnerscarousel";
import React from "react";

function Partners() {
  return (
    <div style={{ margin: "1rem" }}>
      <div>
        <PartnersCarousel />
      </div>
    </div>
  );
}

export default Partners;

import React from "react";
import Styles from "./components/programmes/Styles.module.css";
import SkeletonDisplay from "./components/hooks/skeletonDisplay";

function Nomatch() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                margin: " 0 auto",
              }}
            >
              <img
                style={{ width: "100%" }}
                src="/aboutus/aboutus-curd-africa.png"
                alt="Sustainable World Organization "
              />
            </div>
          </SkeletonDisplay>
        </div>
        <div>
          <h1 style={{ textAlign: "center" }}>Page Not found</h1>
        </div>
      </div>
    </div>
  );
}

export default Nomatch;

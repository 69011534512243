import React from "react";
import Styles from "./Styles.module.css";


const Slider5 = () => {
  return (
    <div className={Styles.slider5}>
      <div className={Styles.mainContainer}>
        <div className={Styles.slider5container}>
        <div className={Styles.subContainer5}>
            <div className={Styles.subSpan}>
              <h1>Countries</h1>
              <h1>5</h1>
            </div>
          </div> <div className={Styles.subContainer5}>
            <div className={Styles.subSpan}>
              <h1>Counties</h1>
              <h1>Kenya</h1>
              <h1>8</h1>
            </div>
          </div>
          <div className={Styles.subContainer5}>
            <div className={Styles.subSpan}>
              <h1>Programs</h1>
              <h1>9</h1>
            </div>
          </div>{" "}
          <div className={Styles.subContainer5}>
            <div className={Styles.subSpan}>
              <h1>Completed</h1>
              <h1>Projects</h1>
              <h1>5</h1>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default Slider5;

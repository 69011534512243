// NavBarData.js
const navLinks = [
  { title: "Home", path: "/" },

  {
    title: "Who we are",
    path: "/",
    subLinks: [
      "About Us",
      "Governance",
      "Our Strategies",
      "Partnerships",
      "Who do we work with",
      
    ],
  },
  {
    title: "What we Do",
    path: "/",
    subLinks: [
      "Student Mentorship",
      "Economic Empowerment",
      "Education",
      "Access to Health",
      "Peace and Cohesion",
      "Land Natural Resource Management",
      "Resilience Social Safety Net Emergency Response",
      "Good Governance",
      "Holistic School-based Peer Mentorship Boot Camp",
      
    ],
  },
  { title: "Gallery", path: "/Gallery" },
  { title: "Online Mentorship Programs", path: "/Online Mentorship Programs" },
  { title: "Contact us", path: "/Contact Us" },
];

export default navLinks;

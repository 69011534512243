// Import necessary libraries
import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const SocialShare = ({ url, title, description, headerImage }) => {
  return (
    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
      {/* Facebook Share Button */}
      <FacebookShareButton
        url={url}
        quote={title}
        hashtag={description ? `#${description.split(" ").join("")}` : ""}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      {/* LinkedIn Share Button */}
      <LinkedinShareButton
        url={url}
        title={title}
        summary={description}
        source={headerImage}
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>

      {/* Twitter Share Button */}
      <TwitterShareButton
        url={url}
        title={title}
        hashtags={description ? description.split(" ") : []}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      {/* WhatsApp Share Button */}
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
    </div>
  );
};

export default SocialShare;

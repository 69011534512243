import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

import SkeletonDisplay from "../../hooks/skeletonDisplay";
import Partners from "./partners/partners";

const PartnershipContent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <Partners/>
        <div className={Styles.themesContainer}>
          <div className={Styles.productCard}>
            <h1>Partnerships </h1>
            <p>
              SWO maintains good relationship with like–minded organizations and
              other stakeholders involved in sustainable development at County,
              National, Regional and International levels. Currently SWO is
              closely working with Women Initiative for Development, Women
              Political Alliance, Every Girls Dream, i–GET, African Women
              Studies Centre, Isiolo Non-State Actors’ Forum among others as
              well as a number of women and youth groups spread out across the
              current target locations. SWO will continue to build more robust
              networks and partnership with other stakeholders so as to enhance
              its effectiveness and its outreach.
            </p>
            <div className={Styles.productCard}>
              <h3>Our Programs</h3>
              {list.map((item, index) => (
                <li key={index}>
                  <Link to={`/${item}`}>{item}</Link>
                </li>
              ))}
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/aboutusswo.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
        </div>
      
      </div>
    </div>
  );
};

export default PartnershipContent;

const list = [
  "Student Mentorship",
  "Economic Empowerment",
  "Education",
  "Access to Health",
  "Peace and Cohesion",
  "Land & Natural Resource Management",
  "Resilience | Social Safety Net| Emergency Response",
  "Good Governance",
  "Holistic School-Based Peer Mentorship Boot Camp",
];

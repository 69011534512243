import React from "react";

import Header from "./components/header/header";
import ShowCase from "./components/slider/showcase/showcase";
import ShowCase2 from "./components/slider/showcase/showcase2";
import ShowCase3 from "./components/slider/showcase/showcase3";
import Slider2 from "./components/slider/Slider2";
import Slider3 from "./components/slider/Slider3";
import Slider9 from "./components/slider/Slider9";
import Slider10 from "./components/slider/Slider10";
import Slider11 from "./components/slider/Slider11";
import Slider5 from "./components/generalpages/aboutussliders/Slider5";

function Home() {
  return (
    <div>
      <Header />
      <Slider2 />
      <ShowCase />
      <Slider3 />
      <ShowCase2 />
      <Slider9 />
      <ShowCase3 />
      <Slider5 />
      <Slider10 />
      <Slider11 />
    </div>
  );
}

export default Home;

import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../hooks/skeletonDisplay";
import ResilienceSocialSafetyNetContent from "./sliders/resiliencesocialsafetyemergencyresponsecontent";

function ResilienceSocialSafetyNet() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img
              src="/aboutus/resiliencesocialsafety.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button> Resilience | Social Safety Net| Emergency Response</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <ResilienceSocialSafetyNetContent />
      </div>
    </div>
  );
}

export default ResilienceSocialSafetyNet;

import React from "react";
import Styles from "./Styles.module.css";
import GovernanceContent from "./sliders/governancecontent";
import SkeletonDisplay from "../hooks/skeletonDisplay";

function Governance() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img
              src="/aboutus/governance.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button> Governance</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <GovernanceContent />
      </div>
    </div>
  );
}

export default Governance;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const GalleryCategory = () => {
  const [resources, setResources] = useState([]);
  const { categoryData } = useParams();
  const galleryData = require("./classroom.json");

  useEffect(() => {
    const fetchData = () => {
      try {
        const category = galleryData.find(
          (cat) => cat.categoryname === categoryData
        );
        setResources(category.photos);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchData();
  });

  return (
    <div>
      <ImageGallery items={resources} />;
    </div>
  );
};

export default GalleryCategory;

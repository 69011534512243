import React from "react";
import Styles from "./Styles.module.css";


import SkeletonDisplay from "../../hooks/skeletonDisplay";

const WhodoWeworkWithcontent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}></div>
        <div className={Styles.bodytext}>
          <h3>WHO DO WE WORK WITH?</h3>
          <p>
            SWO works with a wide range of stakeholders. These include
            grassroots women and youth organizations and local
            leaders/influencers with whom we partner to undertake different
            activities. We also work with key public, private and non-profit
            organisations at the national, county and local levels. We
            collaborate with institutions such as schools where we undertake
            guidance, counselling and mentorship of girls; clinics and hospitals
            with whom we partner to provide reproductive health education and
            theatre/creative arts groups with whom we partner to educate on
            women/youth’ rights, FGM and reproductive health. Our direct
            partners are women, youth and other socially excluded groups (such
            as the disabled) from communities who are disadvantaged and unable
            to realize their full potentials.
          </p>
        </div>
        <div className={Styles.bodytext3}>
          <SkeletonDisplay>
            <img
              src="/whoweare/whodoweworkwith.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
        </div>
      </div>
    </div>
  );
};

export default WhodoWeworkWithcontent;



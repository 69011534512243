// OurStrategies Data.js

const ourstrategiesData = [
  {
    category: "ACTION -RESEARCH:",
    id: 1,
    title: "ACTION -RESEARCH:",
    imageUrl: "/gallery/studentmentorship.jpg",
    description:
      "SWO utilize action-research approaches to bring together action and reflection, theory and practice, in participation with others, and in pursuit of practical solutions to issues of pressing concern to the people we partner with. Through this participatory approach, SWO is able to facilitate self-reflection, self-evaluation and self-management by autonomous and responsible persons and groups. This approach is progressive (and public) learning by doing and making mistakes in a ‘self-reflective spiral’ of planning, acting, observing, reflective and re-planning. Action-research which enables to identify root-causes of community challenges as well as mobilizing collective action forms the basis for SWO’s engagement across the different sectors and interventions.",
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    category: "DATA- DRIVEN DECISION-MAKING: ",
    id: 2,
    title: "DATA- DRIVEN DECISION-MAKING:",
    imageUrl: "/gallery/economicempowerment.jpg",
    description:
      "SWO’s decision to engage in any intervention or initiative is backed up by hard data rather than intuitive or observation alone. SWO collects and analyses data to guide decisions that improve success of each intervention. Advancement in technology has enabled us to be able to collect real-time local level data in almost all fields of human endeavors, making data more fundamental part of all sorts of sectors. Using modern technology-based data analytics frameworks, techniques and tools, SWO is able to identify core problems and use the real-time data to find appropriate and sustainable solutions. Using real-time data, SWO is able to lobby, collaborate, network and advocate for positive change for women, youth and other socially excluded segments of society.",
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    category: "MENTORSHIP, GUIDANCE & COUNSELLING:",
    id: 3,
    title: "MENTORSHIP, GUIDANCE & COUNSELLING:",
    imageUrl: "/gallery/education.jpg",
    description: "SWO has identified local mentors who work with girls in school to guide them through their studies and lives. These mentorship sessions are both individual and group-based and is tailored to individual student’s needs and capabilities through visiting schools to guide and mentor schoolgirls on education and career choices. SWO also organizes mentorship for both youth and women’s groups to improve their economic activities through entrepreneurship hand-holding and linkages.",
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
  {
    category: "NETWORKING, COLLABORATION, LOBBY & ADVOCACY:",
    id: 3,
    title: "NETWORKING, COLLABORATION, LOBBY & ADVOCACY:",
    imageUrl: "/gallery/accesstohealth.jpg",
    description: "SWO works with governments (national & local), CSOs, grassroots women and youth organisations to identify opportunities, challenges and key intervention areas relevant to women, youth and socially excluded groups. SWO works with these groups to lobby and advocate for their rightful role and responsibility to ensure that they amplify voices of women and youth to speak up for their basic rights such as education, health, security, representation and livelihoods.",
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
  {
    category: "NEW & FOLK MEDIA FOR DEVELOPMENT:",
    id: 4,
    title: "NEW & FOLK MEDIA FOR DEVELOPMENT:",
    imageUrl: "/gallery/accesstohealth.jpg",
    description: "SWO works with established local creative arts groups that utilise drama, films and short skits (in local languages and using local contexts) to engage with and pass on critical messages on women’s/youth’s rights, reproductive health, HIV/AIDS and girls’ education. We also utilize new media such as Facebook, WhatsApp, YouTube and Twitter to reach out to younger generation with information and actions that can empower them.",
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];

export default ourstrategiesData;

import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../hooks/skeletonDisplay";
import HolisticSchoolPeerContent from "./sliders/holisticschoolbasedpeerbootcampcontent";

function HolisticSchoolPeer() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img
              src="/aboutus/peerbootcampmentorship.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button> Holistic School-Based Peer Mentorship Boot Camp</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <HolisticSchoolPeerContent />
      </div>
    </div>
  );
}

export default HolisticSchoolPeer;

import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../hooks/skeletonDisplay";
import EconomicEmpowermentContent  from "./sliders/economicempowermentcontent";

function EconomicEmpowerment() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img
              src="/aboutus/economicempowerment.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button> Economic Empowerment</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <EconomicEmpowermentContent />
      </div>
    </div>
  );
}

export default EconomicEmpowerment;

import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

import SkeletonDisplay from "../../hooks/skeletonDisplay";

const EducationContent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Education </h1>
            <p>
              SWO facilitates academic and career guidance and counselling for
              students in high school and universities. Through an innovative
              online mentorship programme, SWO links students (especially girls)
              with mentors who accompany them through their education journey,
              providing the much-needed guidance, counselling and role modeling.
              We visit schools and informal settlements to counsel and mentor
              girls from disadvantaged backgrounds. We also support access to
              vocational and practical skills training for out of school
              children from poor background.
            </p>
            <div className={Styles.productCard}>
              <h3>Make Education Count Program</h3>
              {list.map((item, index) => (
                <li key={index}>
                  <Link to={`#`}>{item}</Link>
                </li>
              ))}
              <p>
                Indirectly, the program will impact on approximately 1,600
                students in 10 schools.
              </p>
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/whatwedo/education.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
            <Link to="/Contact Us" style={{ textDecoration: "none" }}>
              <button>Get in Touch</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationContent;

const list = [
  "1,280 Students",
  "640 Girls",
  "640 Boys",
  "10 School Administrators",
  "10 Parents",
  "5 High Schools in Isiolo",
  " 3 education officials",
];

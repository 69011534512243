import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

import SkeletonDisplay from "../../hooks/skeletonDisplay";

const ResilienceSocialSafetyNetContent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Resilience | Social Safety Net| Emergency Response </h1>
            <p>
              At Sustainable World Organization (SWO), we are dedicated to
              promoting resilience and sustainability in the face of climate
              change. Working hand in hand with local communities and county and
              national governments, we develop and implement climate-smart
              interventions aimed at mitigating the negative impacts of climate
              change. These initiatives empower communities to adapt to changing
              environmental conditions while fostering sustainable practices
              that enhance their livelihoods and protect natural resources.
            </p>
            <div className={Styles.productCard}>
              <h3>Our Programs</h3>
              {list.map((item, index) => (
                <li key={index}>
                  <Link to={`/${item}`}>{item}</Link>
                </li>
              ))}
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/aboutusswo.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div className={Styles.bodytext}>
          <h4>Our Approach</h4>
          <p>
            In times of crisis, SWO provides critical emergency response support
            to communities in need, addressing immediate challenges while laying
            the groundwork for lasting solutions. By integrating emergency
            interventions with long-term resilience programming, we bridge the
            gap between urgent relief and sustainable recovery. Our holistic
            approach ensures that vulnerable populations not only recover from
            climate shocks but also build the capacity to withstand future
            challenges, promoting stability and progress for generations to
            come.
          </p>
          <SkeletonDisplay>
            <img
              src="/aboutus/aboutusbodyimage.png"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
        
        </div>
        
      </div>
    </div>
  );
};

export default ResilienceSocialSafetyNetContent;

const list = [
  "Student Mentorship",
  "Economic Empowerment",
  "Education",
  "Access to Health",
  "Peace and Cohesion",
  "Land & Natural Resource Management",
  "Resilience | Social Safety Net| Emergency Response",
  "Good Governance",
  "Holistic School-Based Peer Mentorship Boot Camp",
];

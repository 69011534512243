import React from "react";
import Styles from "./Styles.module.css";

import { Zoom } from "react-awesome-reveal";

const Slider3 = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <div className={Styles.slide3lightbackground}>
              <h2>Our Vision</h2>
              <h2>
                {" "}
                A just, equitable and sustainable world where all can realize
                their full potential.
              </h2>
            </div>
          </div>
          <div className={Styles.productCard}>
            <Zoom>
              <p></p>
              <img
                src="/aboutus/vision.png"
                alt="Sustainable World Organization "
              />
            </Zoom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider3;

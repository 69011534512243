import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../hooks/skeletonDisplay";
import OnlineMentorshipContent from "./sliders/onlinementorshipcontent";

function OnlineMentorship() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img
              src="/aboutus/onlineprograms.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button> Online Mentorship Program</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <OnlineMentorshipContent/>
      </div>
    </div>
  );
}

export default OnlineMentorship;

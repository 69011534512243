import React from "react";
import Styles from "./Styles.module.css";

import SkeletonDisplay from "../hooks/skeletonDisplay";

const Slider10 = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/aboutswohome1.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
          <div className={Styles.productCard}>
            <h3>Empowering Women Groups Access to Credit</h3>
            <p>
              Access to credit is a transformative tool for empowering women and
              driving socio-economic growth. Across the globe, women's groups
              have proven to be powerful vehicles for community development and
              economic resilience. However, one persistent challenge remains:
              inadequate access to affordable and reliable credit. This article
              explores the significance of credit access for women groups, the
              barriers they face, and actionable steps to bridge this gap.
            </p>
            <h3>The Role of Women Groups in Economic Development</h3>
            <p>
              Women groups, often organized as cooperatives or self-help
              associations, play a critical role in fostering economic
              empowerment. These groups provide members with a platform to pool
              resources, share knowledge, and collectively address financial and
              social challenges. Access to credit enables these groups to:
            </p>

            <div>
              <button className={Styles.infobtnblue}>Learn More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider10;

import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../hooks/skeletonDisplay";
import GoodGovernanceContent from "./sliders/goodgovernancecontent";

function GoodGovernance() {
  return (
    <div>
      <div className={Styles.slider1}>
        <div className={Styles.mainContainer}>
          <SkeletonDisplay>
            <img
              src="/aboutus/goodgovernance.jpg"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <button> Good Governance</button>
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <GoodGovernanceContent />
      </div>
    </div>
  );
}

export default GoodGovernance;

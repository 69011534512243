// GalleryData.js

const galleryData = [
  {
    category: "Student Mentorship",
    id: 1,
    title: "Student Mentorship",
    imageUrl: process.env.PUBLIC_URL + "/gallery/studentmentorship.jpg",
    description: "Student Mentorship",
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    category: "Economic Empowerment ",
    id: 2,
    title: "Economic Empowerment",
    imageUrl: process.env.PUBLIC_URL + "/gallery/economicempowerment.jpg",
    description: "Extra-Curriculla Activities",
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    category: "Education",
    id: 3,
    title: "Education",
    imageUrl: process.env.PUBLIC_URL + "/gallery/education.jpg",
    description: "Education",
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
  {
    category: "Access to Health",
    id: 3,
    title: "Access to Health",
    imageUrl: process.env.PUBLIC_URL + "/gallery/accesstohealth.jpg",
    description: "Access to Health",
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
 
];

export default galleryData;

import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

import SkeletonDisplay from "../../hooks/skeletonDisplay";

const HolisticSchoolPeerContent = () => {
  return (
    <div className={Styles.slider3}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>Holistic School-Based Peer Mentorship Boot Camp </h1>
            <p>
              The Holistic School-Based Peer Mentorship Boot Camp is a
              comprehensive initiative designed to foster mentorship, personal
              growth, and academic success among students. The program leverages
              the power of peer and community mentorship to create supportive
              environments that enable students to thrive. Its key components
              include:
            </p>
            <div className={Styles.productCard}>
              <h3>Programs Components</h3>
              {list.map((item, index) => (
                <li key={index}>
                  <Link to={`#`}>{item}</Link>
                </li>
              ))}
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/aboutus/aboutusswo.png"
                alt="Sustainable World Organization "
              />
            </SkeletonDisplay>
          </div>
        </div>
        <div className={Styles.bodytext}>
          <h4>Our Approach</h4>
          <p>
            We strive to build a critical mass of actors – connecting the
            individuals, groups and organisations in the public, private and
            non-profit sectors in a collaborative and result-oriented manner to
            expand opportunities for the last mile communities in order to
            achieve lasting impacts locally and globally. Our approaches are
            rooted in innovative, participatory and evidence-based, data-driven
            decision making that applies tested (traditional and modern),
            cutting-edge frameworks, techniques and tools that seeks to build
            bottom-up networks and horizontal partnerships for positive, lasting
            change.
          </p>
          <SkeletonDisplay>
            <img
              src="/aboutus/aboutusbodyimage.png"
              alt="Sustainable World Organization "
            />
          </SkeletonDisplay>
          <ul>
            <li>
              Training and equipping students to serve as peer mentors who can
              guide, inspire, and support their classmates.
            </li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HolisticSchoolPeerContent;

const list = [
  "Building the Capacity of School-Based Peer Mentors",
  "Adopt-a-Mentee Scheme where experienced local mentors are linked and provide continuous accompaniment support to mentees,",
  "Wajibika na Elimu Forum to proactively engage with parents and school administration;",
  " On-Call psycho-social support to affected needy students",
  "Material support to needy/vulnerable students in form of tuition bursaries and personal effects.",
];

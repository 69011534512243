import React, { useState } from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";
import { Facebook, Instagram, LinkedIn, WhatsApp, Apartment,  } from "@mui/icons-material";
import {} from "react-icons/fa";

const Slider8 = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
      in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div
      className={Styles.slider8}
      style={{ backgroundColor: "var(--stargreen)!important" }}
    >
      <div className={Styles.mainContainer}>
        <div className={Styles.container8}>
          <div className={Styles.productCard}>
            <div className={Styles.footerHighlight}>
              <h3><Apartment />Nairobi Office: </h3>
              <p> Ojijo Plaza, Ojijo Rd, Parklands.</p>
              <p>P.O. BOX 161 – 00618 Ruaraka – Nairobi Kenya.</p>
              <p>Tel: +254 726 528 460</p>
              <p>Tel: +254 721 932 619</p>
              <p>
              <a href="mailto:admin@Sustainableworld.or.ke">
                admin@Sustainableworld.or.ke</a></p>
              <h3><Apartment/>Isiolo Office:</h3>
              <p> 1st Floor Waqf Building, Isiolo Town</p>
              <p> Tel: +254 782 332 548</p>
              <p>Tel: +254 718 669 652</p>
              <Link to="/">
                <img
                  src="/aboutus/footerlogo.jpg"
                  alt="Sustainable World Organization "
                />
              </Link>
            </div>
          </div>
          <div className={Styles.productCard}>
            <h4>Quick Links</h4>
            {navLinks.map((menulink, index) => (
              <li key={index}>
                <Link
                  style={{
                    paddingTopTop: "-2rem",
                    display: visible ? "inline" : "none",
                  }}
                  onClick={scrollToTop}
                  to={menulink.path}
                >
                  {" "}
                  * {menulink.title}
                </Link>{" "}
              </li>
            ))}
          </div>
          <div className={Styles.productCard}>
            <h4>Key Links</h4>
            {generalLinks.map((menulink, index) => (
              <li key={index}>
                <Link
                  style={{
                    paddingTopTop: "-2rem",
                    display: visible ? "inline" : "none",
                  }}
                  onClick={scrollToTop}
                  to={menulink.path}
                >
                  {" "}
                  * {menulink.title}
                </Link>{" "}
              </li>
            ))}
          </div>
        </div>

        <div className={Styles.footer}>
          <p>
            Copyright : &copy; {new Date().getFullYear()} Sustainable World
            Organization
          </p>
          <div className={Styles.socials}>
            <span>
              <Facebook />
            </span>
            <span>
              <WhatsApp />
            </span>
            <span>
              <Instagram />
            </span>
            <span>
              <LinkedIn />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider8;

const navLinks = [
  { title: "Home", path: "/" },
  {
    title: "Student Mentorship ",
    path: "/Student Mentorship ",
  },
  {
    title: "Economic Empowerment",
    path: "/Economic Empowerment",
  },
  {
    title: "Education",
    path: "/Education",
  },
  {
    title: "Access to Health",
    path: "/Access to Health",
  },
  {
    title: "Peace and Cohesion",
    path: "/Peace and Cohesion",
  },
  {
    title: "Land & Natural Resource Management",
    path: "/Land & Natural Resource Management",
  },
  {
    title: "Resilience | Social Safety Net| Emergency Response",
    path: "/Resilience | Social Safety Net| Emergency Response",
  },
  {
    title: "Good Governance",
    path: "/Good Governance",
  },
  {
    title: "Holistic School-Based Peer Mentorship Boot Camp",
    path: "/Holistic School-Based Peer Mentorship Boot Camp",
  },
];

const generalLinks = [
  { title: "Careers", path: "/Careers" },
  { title: "Sitemap", path: "/Site Map" },
  { title: "Frequently Asked Questions", path: "/Frequently Asked Questions" },
  { title: "Home", path: "/" },
  { title: "Privacy Policy", path: "/Privacy Policy" },
  { title: "Terms of Service", path: "/Terms Of Service" },
  {
    title: "Donate: ",
    path: "/Donate",
  },
  { title: "Contact Us", path: "/Contact Us" },
];

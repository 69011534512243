import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { KeyboardArrowRight } from "@mui/icons-material";

export default function AccordianComponent() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {details.map((item, index) => (
        <Accordion
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
          key={index}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              sx={{ width: "100%", flexShrink: 0 }}
              style={{ fontWeight: "bold" }}
            >
              {item.title}
            </Typography>
          </AccordionSummary>
          {item.description.map((subitem, index2) => (
            <AccordionDetails key={index2}>
              <Typography>
                {" "}
                <KeyboardArrowRight
                  style={{ margin: "-0.5rem", paddingRight: "1.5rem" }}
                />
                {subitem}
              </Typography>
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </div>
  );
}

const details = [
  {
    id: "panel1",
    title: "What does SWO Stand for?",
    description: ["SWO stands for Sustainable World Organization."],
  },
  {
    id: "panel2",
    title: "What does SWO aim to achieve?",
    description: [
      "SWO aims to expand opportunities for last-mile communities by fostering collaboration among public, private, and non-profit actors. It focuses on creating sustainable and lasting impacts through inclusive and result-oriented development programs.",
    ],
  },
  {
    id: "panel3",
    title: "Who does SWO serve?",
    description: [
      "Women and girls",
      "Youth",
      "Socially excluded or marginalized populations",
      "These groups are empowered to realize their full potential and actively participate in sustainable development.",
    ],
  },
  {
    id: "panel4",
    title: "What are SWO’s primary areas of focus?",
    description: [
      "Empowerment of Women and Youth: Enhancing skills, education, and economic opportunities",
      "Community Inclusion: Ensuring marginalized populations are part of development processes",
      "Holistic Sustainability: Addressing environmental, social, and economic challenges",
      "Multi-sectoral Collaboration: Bridging public, private, and non-profit sectors for innovative and impactful solutions",
    ],
  },
  {
    id: "panel5",
    title: "How does SWO ensure collaboration among sectors?",
    description: [
      "SWO brings together individuals, organizations, and groups from various sectors to foster partnerships, share knowledge, and implement joint initiatives. This approach ensures efficient resource utilization and maximizes the impact of development projects.",
    ],
  },
  {
    id: "panel6",
    title: "What has SWO achieved since its establishment?",
    description: [
      "Since 2009, SWO has expanded its focus from women’s empowerment to address broader development challenges. It has successfully implemented programs that:",
      "Empower women and youth in marginalized communities.",
      "Build capacity for sustainable development.",
      "Foster local and national partnerships to tackle systemic issues.",
    ],
  },
  {
    id: "panel7",
    title:
      "How can one get involved with SWO? There are several ways to engage with SWO",
    description: [
      "As a Partner: Collaborate with SWO on development initiatives",
      "As a Volunteer: Contribute your skills and expertise to ongoing projects",
      "As a Donor: Provide financial support for programs and initiatives",
      "As a Beneficiary: Participate in empowerment and training programs",
    ],
  },
  {
    id: "panel8",
    title: "How does SWO measure its impact? SWO measures its impact through",
    description: [
      "Regular monitoring and evaluation of projects",
      "Tracking progress toward local and international development goals",
      "Documenting changes in the lives of its beneficiaries and communities",
    ],
  },
  {
    id: "panel9",
    title: "Does SWO operate outside Kenya?",
    description: [
      "SWO’s primary focus is on sustainable development in Kenya. However, its programs contribute to broader international sustainable development goals, and it collaborates with global partners to create a wider impact.",
    ],
  },
  {
    id: "panel10",
    title: "How can I contact SWO? You can reach SWO through:",
    description: [
      "Email:admin@Sustainableworld.or.ke ",
      "Tel1:+254 726 528 460",
      "Tel: +254 721 932 619",
      "Physical Office: Ojijo Plaza, Ojijo Rd, Parklands.",
    ],
  },
];

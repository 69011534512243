const ShowCases = [
  {
    pillarId: "1",
    title: "Student Mentorship ",
    image: "/pillar/pillar1.png",
    description:
      "Today’s young people are faced with myriad of challenges that affect their academic success, emotional wellbeing and social life,negatively impacting on their lives as responsible, productive and successful citizens.",
    link: "/Student Mentorship ",
  },
  {
    pillarId: "2",
    title: "Economic Empowerment",
    image: "/pillar/pillar2.png",
    description:
      "SWO identifies practical solutions to economic challenges of women and youth and test them out with partner women and youth self-help groups.",
    link: "/Economic Empowerment",
  },
  {
    pillarId: "3",
    title: "Education",
    image: "/pillar/pillar3.png",
    description:
      "SWO facilitates academic and career guidance and counselling for students in high school and universities.Through an innovative online mentorship programme, SWO links students (especially girls) with mentorswho accompany them through their education journey, providing the much-needed guidance,counselling and role modeling.",
    link: "/Education",
  },
  {
    pillarId: "4",
    title: "Access to Health",
    image: "/pillar/pillar4.png",
    description:
      "We offer extensive education and support programs on HIV/AIDS management and address drug abuse through prevention, rehabilitation, and community outreach, assisting individuals in reclaiming their lives.",
    link: "/Access to Health",
  },
  {
    pillarId: "5",
    title: "Peace and Cohesion ",
    image: "/pillar/pillar5.png",
    description:
      "SWO promotes peaceful co-existence among and between communities especially in northern Kenyawho are prone to and are highly affected by conflicts and cattle rustling.",
    link: "/Peace and Cohesion",
  },
  {
    pillarId: "6",
    title: "Land & Natural Resource Management",
    image: "/pillar/pillar6.png",
    description:
      "While land is critical resource for social economic development,its access and utilization remains a bone of contention in most parts of Kenya. ",
    link: "/Land & Natural Resource Management",
  },
  {
    pillarId: "7",
    title: "Resilience | Social Safety Net| Emergency Response",
    image: "/pillar/pillar7.png",
    description:
      "We offer extensive education and support programs on HIV/AIDS management and address drug abuse through prevention, rehabilitation, and community outreach, assisting individuals in reclaiming their lives.",
    link: "/Access to Health",
  },
  {
    pillarId: "8",
    title: "Good Governance ",
    image: "/pillar/pillar8.png",
    description:
      "SWO promotes peaceful co-existence among and between communities especially in northern Kenyawho are prone to and are highly affected by conflicts and cattle rustling.",
    link: "/Peace and Cohesion",
  },
  {
    pillarId: "9",
    title: "Holistic School-Based Peer Mentorship Boot Camp",
    image: "/pillar/pillar9.png",
    description:
      "While land is critical resource for social economic development,its access and utilization remains a bone of contention in most parts of Kenya. ",
    link: "/Land & Natural Resource Management",
  },
];

export { ShowCases };

import React from "react";
import Styles from "./Styles.module.css";


const Slider8 = () => {
  return (
    <div className={Styles.slider8}>
      <div className={Styles.mainContainer}>
        <h1>Privacy Policy</h1>
        <p>
          Sustainable World Organization (SWO) is committed to protecting your
          privacy and ensuring that your personal information is handled in a
          safe and responsible manner. This Privacy Policy outlines how we
          collect, use, disclose, and protect your personal information when you
          interact with our website or participate in our programs and services.
        </p>
        <div className={Styles.slider8Content}>
          <h2>1. Information We Collect</h2>
          <p>We may collect the following types of information:</p>
          <h4>a. Personal Information</h4>
          <ul>
            <li>Name, email address, phone number, and mailing address.</li>
            <li>
              Demographic information such as age, gender, and preferences.
            </li>
          </ul>
          <h4>b. Non-Personal Information</h4>
          <ul>
            <li>IP address, browser type, and operating system..</li>
            <li>
              Details about your interactions with our website, including pages
              visited and links clicked..
            </li>
          </ul>
          <h4>c. Other Information</h4>
          <ul>
            <li>
              Information you voluntarily provide, such as through forms,
              surveys, or program participation.
            </li>
          </ul>
        </div>
        <div className={Styles.slider8Content}>
          <h2>2. How We Use Your Information</h2>
          <p>We use your information to:</p>
          <h4>a. Personal Information</h4>
          <ul>
            <li>
              Provide and improve our services. Monitor website performance and
              user engagement to enhance user experience.
            </li>
            <li>
              Communicate with you regarding our programs, events, and updates.
            </li>
            <li>
              {" "}
              Respond to inquiries, process donations, and manage your
              participation in our initiatives.
            </li>
          </ul>
        </div>
        <div className={Styles.slider8Content}>
          <h2> 3.Sharing Your Information</h2>
          <p>
            SWO respects your privacy and will not sell, rent, or trade your
            personal information. We may share your information only in the
            following cases:
          </p>

          <ul>
            <li>
              With Service Providers: To help us operate our website or deliver
              services on our behalf.
            </li>
            <li>
              Legal Compliance: To comply with applicable laws, regulations, or
              legal processes.
            </li>
            <li>
              With Your Consent: When you explicitly agree to share your
              information.
            </li>
          </ul>
        </div>
        <div className={Styles.slider8Content}>
          <h2> 4. Cookies and Tracking Technologies</h2>
          <p>
            Our website uses cookies and similar technologies to improve your
            browsing experience and analyze website traffic. You can manage your
            cookie preferences through your browser settings.
          </p>
        </div>
        <div className={Styles.slider8Content}>
          <h2> 5. Data Security</h2>
          <p>
            We implement robust security measures to protect your personal
            information from unauthorized access, alteration, or disclosure.
            However, no method of data transmission over the internet is
            completely secure, and we cannot guarantee absolute security.
          </p>
        </div>
        <div className={Styles.slider8Content}>
          <h2> 6. Third-Party Links</h2>
          <p>
            Our website may contain links to third-party websites. SWO is not
            responsible for the privacy practices or content of these external
            sites. We encourage you to review the privacy policies of any
            third-party websites you visit.
          </p>
        </div>
        <div className={Styles.slider8Content}>
          <h2> 7. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access, update, or delete your personal information.</li>
            <li>Opt-out of receiving marketing communications.</li>
            <li>Request a copy of the data we hold about you.</li>
          </ul>
          <p>
            To exercise these rights, please contact us using the information
            provided below.
          </p>
        </div>
        <div className={Styles.slider8Content}>
          <h2> 8. Changes to This Privacy Policy</h2>
          <p>
            SWO may update this Privacy Policy periodically to reflect changes
            in our practices or legal requirements. We will notify you of
            significant updates through our website or other communication
            channels.
          </p>
        </div>
        <div className={Styles.slider8Content}>
          <h2> 9. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at:
          </p>
          <h4>Sustainable World Organization (SWO)</h4>
          <p>Email:admin@Sustainableworld.or.ke </p>
          <p> Tel1:+254 726 528 460</p>
          <p> Tel: +254 721 932 619</p>
          <p> Physical Office: Ojijo Plaza, Ojijo Rd, Parklands</p>
        </div>
      </div>
    </div>
  );
};

export default Slider8;

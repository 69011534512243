import React, { useState } from "react";
import Styles from "./Styles.module.css";

const Slider11 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false); // State for error message

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("http://localhost:5000/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Email sent successfully:", result.message);
        setSubmitted(true);
        setError(false); // Hide error if previously set

        // Reset form fields
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });

        // Hide success message after 3 seconds
        setTimeout(() => {
          setSubmitted(false);
        }, 3000);
      } else {
        console.error("Failed to send email:", await response.json());
        setError(true); // Show error message
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(true); // Show error message
    }
  };

  const isFormDataFilled =
    formData.name !== "" &&
    formData.email !== "" &&
    formData.phone !== "" &&
    formData.message !== "";

  const buttonStyle = {
    backgroundColor: isFormDataFilled ? "var(--stargreen)" : "grey",
    color: "white",
    border: "none",
    padding: "10px 20px",
    cursor: isFormDataFilled ? "pointer" : "not-allowed",
  };

  return (
    <div className={Styles.slider10}>
      <div className={Styles.mainContainer}>
        <div>
          <form onSubmit={handleSubmit} className={Styles.container}>
            <div className={Styles.productCard}>
              <h3>
                Get in Touch with Sustainable World Organization (SWO) Team
              </h3>
              <div className={Styles.slider9InputHolder}>
                <input
                  placeholder="*Name"
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <input
                  placeholder="*Phone No"
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={Styles.productCard}>
              <div className={Styles.slider9leftholder}>
                <p>
                  For more detailed information about Sustainable World
                  Organization, our programs, projects, careers or general
                  enquiries, please write to us below!
                </p>
                <div className={Styles.slider9InputHolder}>
                  <input
                    placeholder="*Email"
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <textarea
                    placeholder="*Nature of Enquiry"
                    rows="1"
                    type="text"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <button
                    type="submit"
                    disabled={!isFormDataFilled}
                    style={buttonStyle}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {submitted && (
          <div className={Styles.successMessage}>
            Thanks for contacting us. Your message is well received. Our team
            will review it and get back to you shortly.
          </div>
        )}
        {error && (
          <div className={Styles.errorMessage}>
            Something went wrong while submitting your message. Please try again
            in a moment.
          </div>
        )}
      </div>
    </div>
  );
};

export default Slider11;
